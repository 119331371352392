
import {Vue,Component,Prop} from 'vue-property-decorator';
import {deleteBusinessApply} from '@/api/businessApply';
import applyDetail from '@/views/mypage/components/ApplyDetail.vue';
@Component({
name: 'MypageApplyDetail',
})
export default class extends Vue { 
@Prop({ default: '' }) private idx!: any;  
@Prop({ default: '' }) private businessName!: any;  
@Prop({ default: '' }) private businessIdx!: any;  
@Prop({ default: 0 }) private applyStatus!: any;  

private handleClickDelete(idx: any) {
deleteBusinessApply(this.idx).then(() => {
alert('지원사업이 삭제처리 되었습니다.');
this.handleClose(); 
})
.catch((error) => {
alert('지원사업의 상태가 변경되어 삭제처리를 진행 할 수 없습니다.'); 
this.handleClose(); 
}); 
}
private handleClose() { 
window.location.reload(); 
this.$emit('close'); 
}
} 
